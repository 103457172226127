*,
::before,
::after {
  box-sizing: border-box;
}

:root {
  /* COLOR */
  --primary-violett: #8800ff;
  --primary-blue: #0066ff;
  --primary-blue-85: #b3d1ff;
  --primary-blue-75: #80b3ff;
  --primary-blue-65: #4d94ff;
  --primary-blue-dark: #0052cc;
  --primary-blue-bright: #b3e0ff;
  --primary-blue-brighter: #ccebff;
  --primary-background: #e6f5ff;
  --primary-blue-link: #005ce6;
  --primary-blue-hover: #0052cc;
  --primary-blue-active: #0047b3;
  --primary-black-95: #f2f2f2;
  --primary-black-80: #cccccc;
  --primary-black-60: #999999;
  --primary-black-40: #666666;
  --primary-green-40: #339933;
  --primary-green-30: #267326;
  --primary-red-70: #ff6666;
  --primary-red-60: #ff3333;
  --primary-red-40: #cc0000;
  --primary-grey-400: #829ab1;
  --primary-tag-background: #e6e6e6;
  

  /* SIZES */
  /* height of navbar and sidebarlogo (should be equal) */
  /* --primary-top-height: "7rem"; */
}

body {
  margin: 0;
  background: var(--primary-background);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Loading-Spinner */

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading {
  width: 6rem;
  height: 6rem;
  border: 5px solid var(--primary-grey-400);
  border-radius: 50%;
  border-top-color: var(--primary-violett);
  animation: spinner 2s linear infinite;
}

.loading-center {
  margin: 0 auto;
  margin-top: 2rem;
}

/* ALERT  */

.alert {
  text-transform: capitalize;
  text-align: center;
  padding: 0.5rem;
  font-size: 1.2rem;
  font-weight: bold;
}

.alert-success {
  color: green;
}

.alert-fail {
  color: red; 
}


/* Button */

.general-button {
    
    padding: 0.5rem;
    border-radius: 5px;
    border-style: none;
    text-decoration: none;
    background-color: var(--primary-blue);
    color: white;
}
.general-button:hover{
      background-color: var(--primary-blue-dark);
      cursor:pointer;
}

/* Nav-Links */

/* .nav-links {
  padding: 1rem;
  gap: 1vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.5rem;
  text-decoration: none;
  
}

.nav-text {
  text-decoration: none;
  align-self: center;
  
}

.icon {
  margin-right: 0.5vw;
} */

/* Form */

.form-label {
  display:block;
  max-width: 6rem;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}

.form-row {
  min-width: 8rem;
}
.form-container{
  padding: 0.25rem;
  margin: 0.25rem;
}


/* this is how react-select works the first is the className-container and then the __control is the "general" styling inputfield */
.form-input-tag-container .form-input-tag__control {
    border-radius: 5px;
    min-height: 2.3rem;
    width: 100%;
    background-color: var(--primary-background);
    border-style: solid;
    border-color: var(--primary-blue-bright);
    border-width: 1px;
  }

  .form-input-tag-container-searchForm {
    width: 16rem;
    transition: 0.3s ease-in-out all;
  }
    .form-input-tag-container-searchForm-hide-search {
      width: 18rem;
      transition: 0.3s ease-in-out all;
    }

  
  
  



.form-input,
.form-select {
  border-radius: 5px;
  height: 2.3rem;
  padding: 0.25rem 0.5rem;
  width: 100%;
  background-color: var(--primary-background);
  border-style: solid;
  border-color: var(--primary-blue-bright);
  border-width: 1px;
}

@media screen and (min-width: 769px) {

.form-input-tag-container .form-input-tag__control {
    border-radius: 5px;
    height: 2.3rem;
    width: 100%;
    background-color: var(--primary-background);
    border-style: solid;
    border-color: var(--primary-blue-bright);
    border-width: 1px;
  }

  .form-input-tag-container-searchForm {
    width: 24rem
  }
    .form-input-tag-container-searchForm-hide-search {
      width: 24rem;
    }
}

